<template>
  <div class="content" :elevation="24">
    <v-alert dense class='alerts' :value='tipsInfo' :type='tipsInfoType'>{{ tipsInfoContent }}</v-alert>
    <div class="login_title">中华人民共和国潍坊海事局AI <span></span> CCTV海面溢油检测系统</div>
    <div class="logink">
      <div class="logink_right">
        <div class="logink_title">登录</div>
        <vv-observer ref="vob" v-slot="{ validate }">
          <div class="login_con">
            <v-img class="longin_search_img" src="../../assets/username.png"></v-img>
            <vv-provider v-slot="{ errors }" rules="required" slim>
              <input placeholder="请输入用户名" v-model="username" class="longin_search" :error-messages="errors" />
              <!-- <v-text-field class="longin_search" label="请输入手机号" solo v-model="username"  :error-messages="errors"></v-text-field> -->
            </vv-provider>
          </div>
          <div class="login_con">
            <v-img class="longin_search_img" src="../../assets/password.png"></v-img>
            <vv-provider v-slot="{ errors }" rules="required" slim>
              <input
                placeholder="请输入密码"
                v-model="password"
                type="password"
                class="longin_search"
                :error-messages="errors"
              />
            </vv-provider>
          </div>
          <div style="margin-left: 24px">
            <v-checkbox v-model="checkbox" class="rememberPS" color="#fff" label="记住密码"></v-checkbox>
          </div>
          <div class="login_btn" :elevation="24" @click="loginInfo(validate)">登录</div>
        </vv-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { getCheckbox, getPaw, getUser } from '@/utils/auth'

export default {
  data() {
    return {
      tipsInfo: false,
      tipsInfoType: 'success',
      tipsInfoContent: '',
      checkbox: getCheckbox() ? getCheckbox() : false,
      username: getUser() ? getUser() : '',
      password: getPaw() ? getPaw() : '',
      deviceId: '',
      deptList: [],
      isshowdept: false,
    }
  },
  created() {
    this.cans()
  },
  methods: {
    //获取浏览器唯一标识
    cans() {
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')
      var txt = 'http://localhost:8081/'
      ctx.textBaseline = 'top'
      ctx.font = "14px 'Arial'"
      ctx.textBaseline = 'tencent'
      ctx.fillStyle = '#f60'
      ctx.fillRect(125, 1, 62, 20)
      ctx.fillStyle = '#069'
      ctx.fillText(txt, 2, 15)
      ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
      ctx.fillText(txt, 4, 17)

      var b64 = canvas.toDataURL().replace('data:image/png;base64,', '')
      var bin = atob(b64)
      var crc = this.bin2hex(bin.slice(-16, -12))
      //var crc = bin.slice(-16,-12);
      this.deviceId = crc
      console.log('唯一标识', crc)
    },

    bin2hex(str) {
      var result = ''
      for (let i = 0; i < str.length; i++) {
        result += this.int16_to_hex(str.charCodeAt(i))
      }
      return result
    },

    int16_to_hex(i) {
      var result = i.toString(16)
      var j = 0
      while (j + result.length < 4) {
        result = '0' + result
        j++
      }
      return result
    },
    async loginInfo(validate) {
      const ok = await validate()
      if (!ok) return
      const data = {
        checkbox: this.checkbox,
        userName: this.username,
        password: this.password,
      }
      await this.$store
        .dispatch('LoginByUsername', data)
        .then((a) => {
          this.$router.push({ path: '/home' })
        })
        .catch((error) => {
          let err = error.data.message
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = err
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
  },
}
</script>
<style lang="scss">

.alerts {
  position: fixed;
  top: 30px;
  width: auto;
  align-self: center;
  margin: 0 auto;
  z-index: 9999;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .login_title{
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-left: -40px;
    span{
      border: 1px solid #fff;
      height: 100px;
      margin: 0 20px;
    }
  }
  .rememberPS {
    width: 100px;
    .v-input__control {
      .v-input__slot {
        .v-label {
          color: #fff;
        }
        .v-icon {
          color: #fff;
          position: relative;
          top: 2px;
        }
      }
    }
  }
  .logink {
    width: 380px;
    height: 380px;
    display: flex;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 4px #ccc;
    border-radius: 10px;
    text-align: center;
    .logink_left_img {
      margin: 0 75px 0 65px;
      display: inline-block;
      width: 392px;
      height: 242px;
      .left_img {
        width: 392px;
        height: 242px;
      }
    }
    .logink_right {
      display: inline-block;
      margin: 0 auto;
      padding-top: 20px;
    }
  }
  .logink_title {
    font-size: 30px;
    color: #acb1b7;
    font-style: normal;
    font-weight: bold;
    width: 336px;
    margin: 0 auto;
    .remember {
      color: #f0f0f0;
    }
  }
  .login_con {
    position: relative;
  }
  .longin_search {
    width: 336px;
    max-width: 336px;
    margin-top: 30px;
    height: 48px;
    background: #eef6ff;
    border-radius: 25px;
    font-size: 14px;
    text-indent: 70px;
    outline: none;
  }
  .longin_search::-webkit-input-placeholder {
    /* placeholder颜色  */
    color: #999999;
    /* placeholder字体大小  */
    font-size: 14px;
    /* placeholder位置  */
    text-align: left;
  }
  .longin_search_img {
    position: absolute;
    top: 46px;
    left: 34px;
  }
  .login_btn {
    width: 336px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: 0 auto;
    background-color: #00b2c7;
    border-radius: 50px;
    cursor: pointer;
  }
  .wangji {
    width: 306px;
    margin: 0 auto;
    text-align: right;
    color: #5473e8;
    font-size: 16px;
  }
}
</style>
